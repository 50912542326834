import { Box, Tab, Tabs } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { IContract, IContractTerms, IUser } from '../../../models';
import { MdOutlineHistory } from 'react-icons/md';
import {
  ContractPanelAction,
  ContractApiValidationIssueType,
  DbgButtonSize,
  Permission,
  ProposalAction,
  TermTypeCategory,
  UserType,
  WorkflowAction,
  WorkflowStatus,
  ValidationSource,
  UpcDataGridFields,
  ValidationScopes,
  ContractPageTabs,
} from '../../../models/enums';
import { HttpErrorResponse, parseHttpErrorArray } from '../../../services/contractHubApi';
import {
  getBlueButtonStyleProps,
  getDarkGreyButtonStyleProps,
  getGreenButtonStyleProps,
  getRedButtonStyleProps,
  getWhiteButtonStyleProps,
} from '../../../styles/themes';
import { DbgRoundedButton } from '../../components/buttons/DbgRoundedButton';
import { RouteEnum } from '../../layout/PageRouter';
import {
  contractTermService,
  contractWorkflowService,
  contractProposalService,
  validationService,
  contractPricingService,
  eventEmitterService,
} from '../../../services';
import ContractHeader from './components/ContractHeader';
import NewContractModal from './components/dialogs/NewContractModal';
import ContractTermModal, { IContractTermModalState } from './components/dialogs/ContractTermModal';
import { v4 } from 'uuid';
import { useImmer } from 'use-immer';
import LumpSumsDataGrid from './components/LumpSumsDataGrid';
import TabPanel from './components/TabPanel';
import DbgDialog from '../../components/dialogs/DbgDialog';
import UpcDataGrid from './components/UpcDataGrid';
import { DbgLoadingSpinner } from '../../components/shared/DbgLoadingSpinner';
import { ContractPageStyles } from './styles/ContractPageStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '../../../images/delete.svg';
import { StyledImg } from '../../../styles/styled/StyledWrappedCommonElements';
import { DialogSharedStyles } from './styles/DialogSharedStyles';
import { ValidationIssue } from '../../../classes/ValidationIssue';
import { IDuplicateContractResponseModel, IError, IInvalidUpcStoreSupplier } from '../../../models/responses';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import environment from 'environment';
import ContractActionPanel from './components/ContractActionPanel';
import TimeAgo from './components/TimeAgo';
import { useEffectDebounced } from '../../../utilities/ReactUtilities';
import { IUIContractPageState, NoneContractPageState, createUIContractPageState } from './UIContractPageState';
import PageHeader from '../../layout/components/PageHeader';
import PageBody from '../../layout/components/PageBody';
import { useContractShowErrorsStore } from '../../../store';
import PricingSection from './sections/pricing/PricingSection';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { ArrayUtils, Button } from '@dierbergs-markets/react-component-library';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContractTermsHistory from './components/dialogs/ContractTermsHistory';
import { useStyles } from 'tss-react';
import constants from '../../../models/consts';

export interface INewPricingFromItemTermState {
  itemTermUniqueId: string;
  dateRange: DateRange<Dayjs>;
}

interface IDuplicateContractModalState {
  message: string;
  contracts?: IDuplicateContractResponseModel[];
}

export enum ContractPageEvents {
  ADD_NEW_PRICING = 'ADD_NEW_PRICING',
  ADD_NEW_PRICING_FROM_ITEM_TERM = 'ADD_NEW_PRICING_FROM_ITEM_TERM',
}

export default function ContractPage() {
  //STATE
  const [contract, setContract] = useImmer<IContract | undefined>(undefined);
  const [originalContract, setOriginalContract] = useImmer<IContract | undefined>(undefined);
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [contractTermModalOpen, setContractTermModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [duplicateContractModalState, setDuplicateContractModalState] = useState<IDuplicateContractModalState | undefined>(undefined);
  const [showDuplicateContractModal, setShowDuplicateContractModal] = useState<boolean>(false);
  const [showDeleteTermConfirmation, setShowDeleteTermConfirmation] = useState<boolean>(false);
  const [showProposedChangesMessageAlready, setShowProposedChangesMessageAlready] = useState<boolean>(false);
  const [beforeProposeChangesMessage, setBeforeProposeChangesMessage] = useState<boolean>(false);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const [isContractDataDirty, setIsContractDataDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [contractTermModalState, setContractTermModalState] = useState<IContractTermModalState | undefined>(undefined);
  const [contractTermsHubConnection, setContractTermsHubConnection] = useState<HubConnection | null>(null);
  const [contractTermsHubConnectionContractId, setContractTermsHubConnectionContractId] = useState<number | null>(null);
  const [contractTermsUpdatedByOtherUser, setContractTermsUpdatedByOtherUser] = useState<boolean>(false);
  const [processValidation, setProcessValidation] = useState<boolean>(false);
  const [contractErrors, setContractErrors] = useState<ValidationIssue[]>([]);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState<WorkflowStatus | null>(null);
  const [uiContractPageState, setUIContractPageState] = useState<IUIContractPageState>(new NoneContractPageState());
  const [hasRetrievedPricing, setHasRetrievedPricing] = useState<boolean>(false);
  const [newPricingFromItemTermState, setNewPricingFromItemTermState] = useState<INewPricingFromItemTermState | null>();
  const [showTermsHistoryDialog, setShowTermsHistoryDialog] = useState<boolean>(false);

  const contractTerms = contract?.terms;
  const { setShowErrors } = useContractShowErrorsStore();

  //MISC. HOOKS
  const { user, referenceData } = useApplicationContextState();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  // CSS
  const { styles } = ContractPageStyles;
  const { styles: dlgStyles } = DialogSharedStyles;
  const { css } = useStyles();

  // USE MEMOS
  const pricingEnabled = useMemo(() => {
    return (user?.userType === UserType.Internal && referenceData?.pricingEnabled) ?? false;
  }, [user, referenceData]);
  const showPricing = pricingEnabled && !!contractTerms && (contractTerms.contractTermsForItem.length > 0 || contractTerms.contractItems.length > 0);

  //USE EFFECTS

  useEffect(() => {
    if (activeTab === ContractPageTabs.Pricing && newPricingFromItemTermState) {
      eventEmitterService.emit(ContractPageEvents.ADD_NEW_PRICING_FROM_ITEM_TERM, newPricingFromItemTermState);
      setNewPricingFromItemTermState(null);
    }
  }, [activeTab, newPricingFromItemTermState]);

  useEffect(() => {
    if (processValidation) validateContract();
  }, [contract, processValidation]);

  useEffect(() => {
    if (contractTermsHubConnection && contractTermsHubConnection.state === HubConnectionState.Connected) return;
    const webSocketUrl = `${environment.baseApiUrl}/ws/contractTerms`;

    const connection = new HubConnectionBuilder().withUrl(webSocketUrl).withAutomaticReconnect().build();
    connection.on('Disconnect', () => {
      connection.stop();
      setContractTermsHubConnection(null);
    });
    connection.on('TermsModified', handleSignalRTermsModified);
    setContractTermsHubConnection(connection);

    return function () {
      connection.stop();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!contractTermsHubConnection) return;

      const contractId = contract?.contractId;
      if (!contractId || contractTermsHubConnectionContractId !== contractId) {
        if (contractTermsHubConnection.state != HubConnectionState.Disconnected) {
          await contractTermsHubConnection.stop();
        }
      }
      if (!contractId || contractId === contractTermsHubConnectionContractId) return;
      setContractTermsHubConnectionContractId(contractId);

      try {
        await contractTermsHubConnection.start();
        await contractTermsHubConnection.invoke('Listen', contractId);
      } catch (ex) {
        //console.log('contract signalr start exception', ex);
      }
    })();
  }, [contractTermsHubConnection, contract?.contractId]);

  useEffect(() => {
    (async () => {
      if (activeTab === ContractPageTabs.Pricing && !hasRetrievedPricing && contract && contract.contractId) {
        await retrievePricing(contract.contractId);
      }
    })();
  }, [activeTab, hasRetrievedPricing, contract?.contractId]);

  useEffect(() => {
    (async () => {
      const contractId = parseInt(id || '') || 0;
      const requestedTab = location.state?.tab ?? ContractPageTabs.Upc;

      if (contractId > 0) {
        await loadContract(contractId, requestedTab);
      } else {
        setContract(location.state.contract);
        setTabForContract(location.state.contract.terms, requestedTab);
        setProcessValidation(false);
        if (location.state.contract.originalContractId) handleEditContract();
      }

      setShowErrors(false);
    })();
  }, [id]);

  async function loadContract(contractId: number, requestedTab?: string | null) {
    setLoading(true);
    const contractResponse = await loadContractTerms(contractId);
    await loadContractPricing(contractId);
    await loadContractWorkflow(contractId);
    setTabForContract(contractResponse?.terms, requestedTab);
    setProcessValidation(false);
    setShowErrors(false);
    setLoading(false);
  }

  async function loadContractTerms(contractId: number): Promise<IContract | null> {
    const contractResponse = await contractTermService.getContract(contractId);
    if (contractResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving contract.', { variant: 'error' });
      return null;
    } else {
      setStateForExistingContract(contractResponse);
      return contractResponse;
    }
  }

  async function loadContractWorkflow(contractId: number) {
    const workflowResponse = await contractWorkflowService.getWorkflowStatus(contractId);
    if (workflowResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving workflow status.', { variant: 'error' });
    } else {
      setCurrentWorkflowStatus(workflowResponse?.status ?? null);
    }
  }

  async function loadContractPricing(contractId: number) {
    if (!pricingEnabled) return;

    const pricingResponse = await contractPricingService.getPricing(contractId);
    if (pricingResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving contract pricing.', { variant: 'error' });
    } else {
      setContract((draftContract) => {
        if (!draftContract) return;
        draftContract.pricings = pricingResponse;
      });
      //apply pricing response to orginalContract object
      setOriginalContract((draftOriginalContract) => {
        if (!draftOriginalContract) return;
        draftOriginalContract.pricings = pricingResponse;
      });
      setHasRetrievedPricing(true);
    }
  }

  useEffectDebounced(
    () => {
      setIsContractDataDirty(!!originalContract && !!contract && JSON.stringify(originalContract) != JSON.stringify(contract));
    },
    [originalContract, contract],
    500
  );

  useEffectDebounced(
    () => {
      setUIContractPageState(createUIContractPageState(currentWorkflowStatus, user?.userType, contractTerms?.isProposed ?? false));
    },
    [currentWorkflowStatus, user?.userType, contractTerms?.isProposed],
    500
  );

  //COMPUTED FIELDS
  const canModify = userCanEditContract(user, contract) && !contractTermsUpdatedByOtherUser && !saving;

  const hasComponents =
    contractTerms &&
    (contractTerms.contractTermsForLumpSum.length > 0 || contractTerms.contractTermsForItem.length > 0 || contractTerms.contractItems.length > 0);

  //ASYNC FUNCTIONS
  async function validateContract(): Promise<ValidationIssue[] | undefined> {
    if (contractTerms) {
      const uiValidationIssues = await validationService.validateContract(contract, contractTerms, referenceData);
      const apiErrors = contractErrors?.filter((e) => e.source === ValidationSource.Api) ?? [];
      setContractErrors([...apiErrors, ...(uiValidationIssues ?? [])]);
      return uiValidationIssues;
    }
  }

  async function updateWorkflowStatus(contractId: number, status: WorkflowStatus) {
    const response = await contractWorkflowService.createWorkflowStatus(contractId, status);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error updating workflow status.', { variant: 'error' });
    } else {
      setCurrentWorkflowStatus(response.status);
    }
  }

  async function keepProposal(contractId: number) {
    const response = await contractProposalService.keepContractProposal(contractId, { pricings: contract?.pricings });
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar(`Error keeping the proposal.`, { variant: 'error' });
    } else {
      setContract(response);
    }
  }

  async function declineProposal(contractId: number) {
    const response = await contractProposalService.declineContractProposal(contractId);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar(`Error declining the proposal.`, { variant: 'error' });
    } else {
      setContract(response);
    }
  }

  async function handleContractPanelAction(action: ContractPanelAction) {
    if (!contract || !contract.contractId) return;

    if (action === ProposalAction.KEEP_PROPOSAL) await keepProposal(contract.contractId);
    else if (action === ProposalAction.DROP_PROPOSAL) await declineProposal(contract.contractId);
    else if (action === WorkflowAction.ACCEPT) await updateWorkflowStatus(contract.contractId, WorkflowStatus.ACCEPTED);
    else if (action === WorkflowAction.SUBMIT) await updateWorkflowStatus(contract.contractId, WorkflowStatus.SUBMITTED);
  }

  interface ISaveResult {
    contractId: number;
  }
  /**
   * @returns Updated contract if successful
   */
  async function performContractSave(): Promise<ISaveResult | null> {
    if (!contract) return null;

    setSaving(true);
    setProcessValidation(true);
    setShowErrors(true);

    const validationIssues = await validateContract();
    if (validationIssues) {
      for (const error of validationIssues) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }

      setSaving(false);
      return null;
    }

    const isNew = (contract.contractId ?? 0) === 0;
    const contractIdOrError = await saveContract(contract);
    if (contractIdOrError instanceof HttpErrorResponse) {
      const apiErrors: IError[] = await parseHttpErrorArray(contractIdOrError);

      const apiErrorsByType = ArrayUtils.groupBy(apiErrors, (x) => x.type).flatMap((g) => ({ type: g.key, errors: g.items }));

      handleApiErrorsByType(apiErrorsByType);

      setSaving(false);
      return null;
    }

    setContractErrors((prev) => prev?.filter((e) => e.source !== ValidationSource.Api));

    const message = uiContractPageState.showSaveProposal
      ? `Proposed changes successfully saved`
      : `Contract successfully ${isNew ? 'created' : 'updated'}.`;

    enqueueSnackbar(message, { variant: 'success' });
    setSaving(false);

    return { contractId: contractIdOrError };
  }

  async function saveContract(contract: IContract): Promise<number | HttpErrorResponse> {
    if (!contract.contractId) {
      const createResponse = await contractTermService.createContract(contract);
      if (createResponse instanceof HttpErrorResponse) return createResponse;
      return createResponse.contractId;
    }

    const updateResponse = uiContractPageState.showSaveProposal
      ? await contractProposalService.createContractProposal(contract.contractId, { terms: contract.terms })
      : await contractTermService.updateContract(contract.contractId, { terms: contract.terms, pricings: contract.pricings });
    if (updateResponse instanceof HttpErrorResponse) return updateResponse;
    return contract.contractId;
  }

  async function retrievePricing(contractId: number) {
    const response = await contractPricingService.getPricing(contractId);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving contract pricing.', { variant: 'error' });
    } else {
      setContract((draftContract) => {
        if (!draftContract) return;
        draftContract.pricings = response;
      });
      //apply pricing response to orginalContract object
      setOriginalContract((draftOriginalContract) => {
        if (!draftOriginalContract) return;
        draftOriginalContract.pricings = response;
      });
      setHasRetrievedPricing(true);
    }
  }

  async function handleContractSaveAndReload() {
    const updatedContract = await performContractSave();
    if (updatedContract) {
      if (updatedContract.contractId.toString() !== id)
        navigate(`${RouteEnum.Contract}/${updatedContract.contractId}`, { state: { tab: activeTab } });
      else await loadContract(updatedContract.contractId, activeTab);
    }
  }

  async function handleContractSaveAndClose() {
    if (await performContractSave()) navigate(RouteEnum.Dashboard);
  }

  async function handleProposeChanges() {
    setBeforeProposeChangesMessage(false);
    await handleContractSaveAndReload();
  }

  async function handleContractDelete() {
    if (!contract) return;
    if (!contract.contractId) {
      navigate(RouteEnum.Dashboard);
      return;
    }

    const success = await contractTermService.deleteContract(contract.contractId);

    if (!success) {
      enqueueSnackbar('Unable to delete contract.', { variant: 'error' });
    } else {
      enqueueSnackbar('Contract successfully deleted', { variant: 'success' });
      navigate(RouteEnum.Dashboard);
    }
  }

  async function handleReturnToAllContracts() {
    if (JSON.stringify(originalContract) != JSON.stringify(contract) && uiContractPageState.canSaveOrProposeChange()) {
      setShowUnsavedChanges(true);
    } else {
      navigate(RouteEnum.Dashboard);
    }
  }

  // FUNCTIONS - MISC.
  function handleApiErrorsByType(errorGroupings: { type: string; errors: IError[] }[]) {
    setShowDuplicateContractModal(false);
    const apiErrors: ValidationIssue[] = [];

    errorGroupings.forEach((g) => {
      switch (g.type) {
        case ContractApiValidationIssueType.DuplicateContractFound:
          g.errors.forEach((error: IError<IDuplicateContractResponseModel[]>) => {
            setDuplicateContractModalState({ message: error.message, contracts: error.data });
            setShowDuplicateContractModal(true);
          });
          break;
        case ContractApiValidationIssueType.InvalidUpcStoreSupplier:
          g.errors.forEach((error: IError<IInvalidUpcStoreSupplier>) => {
            const issues = error.data.skus.map(
              (sku) =>
                new ValidationIssue({
                  displayField: 'Upc',
                  source: ValidationSource.Api,
                  scope: ValidationScopes.ContractItems,
                  field: UpcDataGridFields.ItemUpc,
                  message: "This item's supplier does not match the contract supplier or its stores.",
                  identifier: sku,
                })
            );
            apiErrors.push(...issues);
          });
          g.errors.forEach((error) => enqueueSnackbar(error.message, { variant: 'error' }));

          break;

        default:
          g.errors.forEach((error) => enqueueSnackbar(error.message, { variant: 'error' }));
          break;
      }
    });

    const uiErrors = contractErrors?.filter((e) => e.source === ValidationSource.Ui) ?? [];
    setContractErrors([...uiErrors, ...apiErrors]);
  }

  function setStateForExistingContract(contract: IContract) {
    setContract(contract);
    setOriginalContract(contract);
    setProcessValidation(true);
  }

  function handleSignalRTermsModified(userId: number) {
    //user may have two tabs open, but for now assume they know what they are doing - they'll get an error if they try to save
    if (user && user.id == userId) return; //modified by current user
    enqueueSnackbar('Contract terms have been modified by another user. Please refresh.', { variant: 'warning' });
    setContractTermsUpdatedByOtherUser(true);
  }
  function userCanEditContract(user: IUser | undefined, contract: IContract | undefined): boolean {
    if (!user || !contract) return false;
    return contract.contractId === 0 ? user.permissions.includes(Permission.TermsCreate) : user.permissions.includes(Permission.TermsModify);
  }

  function handleDiscardChanges() {
    navigate(RouteEnum.Dashboard);
  }

  //FUNCTIONS - CONTRACT MODAL

  function setTabForContract(contractTerms?: IContractTerms, requested?: string | null) {
    if (requested) {
      setActiveTab(requested);
      return;
    }

    if (!contractTerms) return;
    if (contractTerms.contractTermsForItem && contractTerms.contractTermsForItem.length > 0) setActiveTab(TermTypeCategory.Upc);
    else if (contractTerms.contractTermsForLumpSum && contractTerms.contractTermsForLumpSum.length > 0) setActiveTab(TermTypeCategory.LumpSum);
  }

  function onContractModalContinue(contract: IContract) {
    setContract(contract);
    setContractModalOpen(false);
  }

  function isCellDisabled(termUnitOfMeasureId: number, isRandomWeight?: boolean) {
    return (
      (referenceData?.termTypeUnitsOfMeasure.byId[termUnitOfMeasureId].forRandomWeight && !isRandomWeight) ||
      (!referenceData?.termTypeUnitsOfMeasure.byId[termUnitOfMeasureId].forRandomWeight && isRandomWeight)
    );
  }

  function handleEditContract() {
    setContractModalOpen(true);
  }

  //FUNCTIONS - TERMS

  function onContractTermModalCancel() {
    setContractTermModalOpen(false);
    setContractTermModalState(undefined);
  }

  function onContractTermModalContinue(savedTermState: IContractTermModalState) {
    if (!referenceData || !savedTermState.uniqueId || !savedTermState.termTypeId || !contract) return;

    if (referenceData.termTypes.byId[savedTermState.termTypeId].category === TermTypeCategory.LumpSum) {
      setContract((draftContract) => {
        if (
          !draftContract ||
          !savedTermState.termTypeId ||
          !savedTermState.departmentId ||
          savedTermState.amount == undefined ||
          !savedTermState.effectiveDate
        )
          return;

        const termIndex = draftContract.terms.contractTermsForLumpSum.findIndex((t) => t.uniqueId === savedTermState.uniqueId);

        if (termIndex === -1) {
          draftContract.terms.contractTermsForLumpSum.push({
            uniqueId: savedTermState.uniqueId ?? v4(),
            termTypeId: savedTermState.termTypeId,
            departmentId: savedTermState.departmentId,
            effectiveDate: savedTermState.effectiveDate,
            amount: savedTermState.amount,
            digitalRewards: savedTermState.digitalRewards ?? undefined,
          });
        } else {
          draftContract.terms.contractTermsForLumpSum[termIndex].departmentId = savedTermState.departmentId;
          draftContract.terms.contractTermsForLumpSum[termIndex].effectiveDate = savedTermState.effectiveDate;
          draftContract.terms.contractTermsForLumpSum[termIndex].amount = savedTermState.amount;
          draftContract.terms.contractTermsForLumpSum[termIndex].uniqueId = savedTermState.uniqueId ?? v4();
          draftContract.terms.contractTermsForLumpSum[termIndex].digitalRewards = savedTermState.digitalRewards ?? undefined;
        }
      });
    } else if (referenceData.termTypes.byId[savedTermState.termTypeId].category === TermTypeCategory.Upc) {
      setContract((draftContract) => {
        if (
          !draftContract ||
          !savedTermState.termTypeId ||
          !savedTermState.termUnitOfMeasureId ||
          !savedTermState.startDate ||
          !savedTermState.endDate
        )
          return;

        const termIndex = draftContract.terms.contractTermsForItem.findIndex((t) => t.uniqueId === savedTermState.uniqueId);

        if (termIndex === -1) {
          draftContract.terms.contractTermsForItem.push({
            uniqueId: savedTermState.uniqueId ?? v4(),
            termTypeId: savedTermState.termTypeId,
            termUnitOfMeasureId: savedTermState.termUnitOfMeasureId,
            startDate: savedTermState.startDate,
            endDate: savedTermState.endDate,
          });
          draftContract.terms.contractItems.forEach((q) => {
            if (savedTermState.termUnitOfMeasureId) {
              q.amounts.push(isCellDisabled(savedTermState.termUnitOfMeasureId, q.item?.isRandomWeight) ? 0 : undefined);
            } else {
              q.amounts.push(undefined);
            }
            q.uniqueId = q.uniqueId ?? v4();
          });
        } else {
          draftContract.terms.contractTermsForItem[termIndex].termUnitOfMeasureId = savedTermState.termUnitOfMeasureId;
          draftContract.terms.contractTermsForItem[termIndex].startDate = savedTermState.startDate;
          draftContract.terms.contractTermsForItem[termIndex].endDate = savedTermState.endDate;
          draftContract.terms.contractTermsForItem[termIndex].uniqueId = savedTermState.uniqueId ?? v4();
        }
      });
    }

    savedTermState.termTypeId && setActiveTab(referenceData.termTypes.byId[savedTermState.termTypeId].category);
    setContractTermModalOpen(false);
    setContractTermModalState(undefined);
  }

  function handleLumpSumDelete(termUniqueId: string) {
    setContract((draftContract) => {
      if (!draftContract) return;
      const termIndex = draftContract.terms.contractTermsForLumpSum.findIndex((t) => t.uniqueId === termUniqueId);

      if (termIndex !== -1) draftContract.terms.contractTermsForLumpSum.splice(termIndex, 1);
    });
  }

  function handleUpcTermDelete(termUniqueId: string) {
    setContract((draftContract) => {
      if (!draftContract) return;

      const termIndex = draftContract.terms.contractTermsForItem.findIndex((t) => t.uniqueId === termUniqueId);

      if (termIndex !== -1) {
        draftContract.terms.contractTermsForItem.splice(termIndex, 1);
        draftContract.terms.contractItems.forEach((item) => {
          item.amounts.splice(termIndex, 1);
        });
      }
    });
  }

  function onContractTermModalDelete() {
    setShowDeleteTermConfirmation(false);
    if (!contractTermModalState) return;

    if (contractTermModalState.termTypeCategory === TermTypeCategory.Upc) {
      handleUpcTermDelete(contractTermModalState.uniqueId);
    } else if (contractTermModalState.termTypeCategory === TermTypeCategory.LumpSum) {
      handleLumpSumDelete(contractTermModalState.uniqueId);
    }
    setContractTermModalState(undefined);
  }

  function onContractModalCancel() {
    setContractModalOpen(false);
  }

  function handleAddInitialPricingClick() {
    eventEmitterService.emit(ContractPageEvents.ADD_NEW_PRICING);
  }

  function handleAddTermClick() {
    if (!contract) return;

    setContractTermModalState({
      contractId: contract.contractId,
      termTypeCategory: activeTab,
      uniqueId: v4(),
    });
    setContractTermModalOpen(true);
  }

  // SUB-COMPONENTS

  function AddButton(props: { id: string; onClick: () => void; label: string }) {
    return (
      <>
        {canModify && uiContractPageState.canSaveOrProposeChange() && (
          <DbgRoundedButton
            id={props.id}
            dbgButtonSize={DbgButtonSize.Large}
            styleProps={getBlueButtonStyleProps()}
            sx={styles.addPricingButton}
            onClick={props.onClick}
          >
            {props.label}
          </DbgRoundedButton>
        )}
      </>
    );
  }

  function ErrorIcon(tabSelection: string) {
    if (contractErrors.length === 0) return <></>;
    const iconElement = <ErrorOutlineIcon />;

    const errorScopes = Array.from(new Set(contractErrors.map((e) => e.scope)));

    switch (tabSelection) {
      case ContractPageTabs.Upc:
        if (
          [ValidationScopes.ContractItem, ValidationScopes.ContractItems, ValidationScopes.ContractTermsForItem].some((scope) =>
            errorScopes.includes(scope)
          )
        )
          return iconElement;
        break;
      case ContractPageTabs.LumpSum:
        if ([ValidationScopes.ContractTermsForLumpSum].some((scope) => errorScopes.includes(scope))) return iconElement;
        break;
      case ContractPageTabs.Pricing:
        if ([ValidationScopes.Pricings].some((scope) => errorScopes.includes(scope))) return iconElement;
        break;

      default:
        return <></>;
    }
  }

  const AddTermButton = () => (
    <>
      {canModify && uiContractPageState.canSaveOrProposeChange() && (
        <DbgRoundedButton
          id="addTerm"
          dbgButtonSize={DbgButtonSize.Large}
          styleProps={getBlueButtonStyleProps()}
          sx={styles.addTermButton}
          onClick={handleAddTermClick}
        >
          + {constants.terms.displayNamePlural}
        </DbgRoundedButton>
      )}
    </>
  );

  if (saving) {
    return (
      <Box sx={[styles.centeredMessage]}>
        <DbgLoadingSpinner sx={styles.centeredMessage.spinner} id={'contractSaving'} />
        <Box>Saving contract...please wait.</Box>
      </Box>
    );
  }
  if (loading) {
    return (
      <Box sx={[styles.centeredMessage]}>
        <DbgLoadingSpinner sx={styles.centeredMessage.spinner} id={'contractLoading'} />
        <Box>Loading contract...please wait.</Box>
      </Box>
    );
  }

  if (!contract || !contractTerms) return <Box sx={[styles.centeredMessage]}>Contract not found.</Box>;

  return (
    <>
      <PageHeader>
        <Box sx={styles.contractPageHeader}>
          <DbgRoundedButton id="BackToDashboard" onClick={handleReturnToAllContracts} sx={styles.headerBtn} dbgButtonSize={DbgButtonSize.Large}>
            <ArrowBackIcon sx={styles.headerBtnIcon} />
          </DbgRoundedButton>
          {canModify && (
            <Box sx={[styles.headerRight]}>
              {uiContractPageState.canSaveOrProposeChange() && (
                <DbgRoundedButton
                  id="EditContract"
                  title="Edit Contract"
                  onClick={handleEditContract}
                  sx={styles.headerBtn}
                  dbgButtonSize={DbgButtonSize.Large}
                >
                  <EditOutlinedIcon sx={styles.headerBtnIcon} />
                </DbgRoundedButton>
              )}
              {uiContractPageState.canDeleteContract && (
                <DbgRoundedButton
                  id="DeleteContract"
                  title="Delete Contract"
                  onClick={() => setShowDeleteConfirmation(true)}
                  sx={styles.headerBtn}
                  dbgButtonSize={DbgButtonSize.Large}
                >
                  <StyledImg src={DeleteIcon} sx={styles.headerBtnIcon} />
                </DbgRoundedButton>
              )}
            </Box>
          )}
        </Box>
      </PageHeader>
      <PageBody>
        <ContractHeader contract={contract} contractTerms={contractTerms} editContract={handleEditContract} errors={contractErrors} />
        <Box sx={styles.navBar}>
          <Box sx={styles.backContainer}>
            <>
              {uiContractPageState.showSaveButton && canModify && hasComponents && (
                <DbgRoundedButton
                  id="SaveContract"
                  dbgButtonSize={DbgButtonSize.Large}
                  styleProps={getGreenButtonStyleProps()}
                  sx={{ ...styles.saveContractBtn, width: '90px' }}
                  onClick={handleContractSaveAndReload}
                  disabled={saving}
                >
                  {saving ? (
                    <>
                      <DbgLoadingSpinner id="saving" sx={[styles.saveContractBtn.savingIcon]} />
                      Saving...
                    </>
                  ) : (
                    <>Save</>
                  )}
                </DbgRoundedButton>
              )}
              {uiContractPageState.showSaveProposal && isContractDataDirty && canModify && hasComponents && (
                <DbgRoundedButton
                  id="SaveContract"
                  dbgButtonSize={DbgButtonSize.Large}
                  styleProps={getGreenButtonStyleProps()}
                  sx={{ ...styles.saveContractBtn, width: '150px' }}
                  onClick={() => setBeforeProposeChangesMessage(true)}
                  disabled={saving}
                >
                  {saving ? (
                    <>
                      <DbgLoadingSpinner id="saving" sx={[styles.saveContractBtn.savingIcon]} />
                      Saving...
                    </>
                  ) : (
                    <>Propose Changes</>
                  )}
                </DbgRoundedButton>
              )}
              {uiContractPageState.showProposedVersionLabel && <Box sx={[styles.version, styles.version.proposed]}>PROPOSED VERSION</Box>}
              {uiContractPageState.showCurrentVersionLabel && <Box sx={[styles.version, styles.version.current]}>CURRENT VERSION</Box>}
              {contract.createdAtUtc && (
                <Box style={styles.timeAgo}>
                  Last saved <TimeAgo createdAtUtc={contract.createdAtUtc.toISOString()} />.
                </Box>
              )}
            </>
            {contractTermsUpdatedByOtherUser && (
              <DbgRoundedButton
                id="SaveContract"
                styleProps={getGreenButtonStyleProps()}
                sx={{ ...styles.saveContractBtn, width: '120px' }}
                dbgButtonSize={DbgButtonSize.Large}
                disabled
              >
                <span style={{ whiteSpace: 'nowrap' }}>Terms Modified</span>
              </DbgRoundedButton>
            )}
          </Box>
          <Box sx={{ alignSelf: 'flex-end' }}>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              centered
              sx={styles.tabsRoot}
              TabIndicatorProps={{ sx: styles.tabIndicator }}
            >
              {(contractTerms.contractTermsForItem.length > 0 ||
                contractTerms.contractItems.length > 0 ||
                contractTerms.contractTermsForLumpSum.length > 0) && (
                <Tab label="UPCs" id="upcs" value={ContractPageTabs.Upc} icon={ErrorIcon(ContractPageTabs.Upc)} iconPosition="end" />
              )}
              {contractTerms.contractTermsForLumpSum.length > 0 && (
                <Tab
                  label={constants.terms.displayNamePlural}
                  id="lumpsums"
                  value={ContractPageTabs.LumpSum}
                  icon={ErrorIcon(ContractPageTabs.LumpSum)}
                  iconPosition="end"
                />
              )}
              {showPricing && (
                <Tab label="Pricing" id="pricing" value={ContractPageTabs.Pricing} icon={ErrorIcon(ContractPageTabs.Pricing)} iconPosition="end" />
              )}
            </Tabs>
          </Box>
          {hasComponents && (
            <Box sx={styles.actionBtnContainer}>
              {showPricing && activeTab === ContractPageTabs.Pricing ? (
                <AddButton id="addPricing" label="+ Add Pricing" onClick={handleAddInitialPricingClick} />
              ) : (
                <AddTermButton />
              )}
            </Box>
          )}
        </Box>
        <Box sx={styles.mainContent}>
          {!hasComponents && (
            <Box sx={styles.getStarted}>
              <Box sx={styles.getStartedLabel}>Add a {constants.terms.displayName} to get started.</Box>
              <AddTermButton />
            </Box>
          )}
          {activeTab && (
            <Box>
              <TabPanel value={activeTab} index={ContractPageTabs.LumpSum}>
                {contractTerms.contractTermsForLumpSum.length > 0 && (
                  <LumpSumsDataGrid
                    contractTerms={contractTerms}
                    canModify={canModify && uiContractPageState.canSaveOrProposeChange()}
                    onTermEdit={(term) => {
                      setContractTermModalState({
                        contractId: contract.contractId,
                        termTypeCategory: term.termTypeId ? referenceData && referenceData.termTypes.byId[term.termTypeId].category : activeTab,
                        termTypeId: term.termTypeId,
                        uniqueId: term.uniqueId || v4(),
                        amount: term.amount,
                        effectiveDate: term.effectiveDate,
                        departmentId: term.departmentId,
                        digitalRewards: term.digitalRewards,
                      });
                      setContractTermModalOpen(true);
                    }}
                    errors={contractErrors}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index={ContractPageTabs.Upc}>
                {(contractTerms.contractTermsForItem.length > 0 ||
                  contractTerms.contractItems.length > 0 ||
                  contractTerms.contractTermsForLumpSum.length > 0) && (
                  <UpcDataGrid
                    contract={contract}
                    contractTerms={contractTerms}
                    contractLoading={loading}
                    storeSelections={contractTerms.stores}
                    canModify={uiContractPageState.canSaveOrProposeChange() && canModify}
                    onTermEdit={(term) => {
                      setContractTermModalState({
                        contractId: contract.contractId,
                        termTypeCategory: term.termTypeId ? referenceData && referenceData.termTypes.byId[term.termTypeId].category : activeTab,
                        termTypeId: term.termTypeId,
                        termUnitOfMeasureId: term.termUnitOfMeasureId,
                        uniqueId: term.uniqueId || v4(),
                        startDate: term.startDate,
                        endDate: term.endDate,
                        contractItems: contractTerms.contractItems,
                        contractTermsForItem: contractTerms.contractTermsForItem,
                      });

                      setContractTermModalOpen(true);
                    }}
                    onContractItemsChange={(contractItems) => {
                      setContract((draftContract) => {
                        if (!draftContract) return;
                        //update amount to zero if the cell is disabled
                        const contractItemsToUpdate = contractItems.map((contractItem) => {
                          const updatedContractItem = { ...contractItem };
                          const amountsToUpdate = [...updatedContractItem.amounts];
                          draftContract.terms.contractTermsForItem.forEach((contractTerms, t) => {
                            if (isCellDisabled(contractTerms.termUnitOfMeasureId, updatedContractItem.item?.isRandomWeight)) {
                              amountsToUpdate.splice(t, 1, 0);
                            }
                          });
                          updatedContractItem.amounts = amountsToUpdate;
                          draftContract.pricings?.forEach((p) => {
                            if (!p.items.find((i) => i.sku === updatedContractItem.sku)) {
                              p.items.push({ sku: updatedContractItem.sku, multiple: 1, price: 0, orderSurvey: false });
                            }
                          });

                          return updatedContractItem;
                        });
                        draftContract.terms.contractItems = contractItemsToUpdate;
                      });
                    }}
                    onAutoIncludeAssociatedItemsCheck={(checked) => {
                      setContract((draftContract) => {
                        if (!draftContract) return;
                        draftContract.terms.autoAddAssociatedItems = checked;
                      });
                    }}
                    onNewPricingFromItemTerm={(itemTermUniqueId, startDate, endDate) => {
                      setActiveTab(ContractPageTabs.Pricing);
                      const dateRange: DateRange<Dayjs> = [dayjs(startDate), dayjs(endDate)];
                      setNewPricingFromItemTermState({ itemTermUniqueId, dateRange });
                    }}
                    errors={contractErrors}
                  />
                )}
              </TabPanel>
              <TabPanel value={activeTab} index={ContractPageTabs.Pricing}>
                {showPricing && (
                  <PricingSection
                    setContract={setContract}
                    contract={contract}
                    canModify={canModify && uiContractPageState.canSaveOrProposeChange()}
                  />
                )}
              </TabPanel>
              <Box sx={styles.footerWrapper}>
                <Box sx={styles.footer}>
                  {user && hasComponents && (
                    <ContractActionPanel
                      uiContractPageState={uiContractPageState}
                      currentWorkflowStatus={currentWorkflowStatus}
                      onUpdate={handleContractPanelAction}
                      contract={contract}
                    ></ContractActionPanel>
                  )}
                  {user?.userType === UserType.Internal && (
                    <Button
                      id="ContractHistory"
                      title="View Cumulative Contract Changes"
                      onClick={() => setShowTermsHistoryDialog(true)}
                      variant="rounded-sides"
                      startIcon={<MdOutlineHistory />}
                      text="Cumulative Changes"
                      color="black"
                      className={css(styles.historyButton)}
                    ></Button>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </PageBody>
      {contract?.contractId && showTermsHistoryDialog && (
        <ContractTermsHistory contractId={contract.contractId} onClose={() => setShowTermsHistoryDialog(false)} />
      )}
      {contract && contractModalOpen && (
        <NewContractModal onContinue={onContractModalContinue} onCancel={onContractModalCancel} initialContract={contract} />
      )}
      {contractTermModalOpen && contractTermModalState && (
        <ContractTermModal
          initialState={contractTermModalState}
          onDelete={() => uiContractPageState.canSaveOrProposeChange() && setShowDeleteTermConfirmation(true)}
          onContinue={onContractTermModalContinue}
          onCancel={onContractTermModalCancel}
        />
      )}
      <DbgDialog
        id="duplicateContractModal"
        open={showDuplicateContractModal}
        sx={styles.duplicateContractModal}
        confirmButtonTheme={getRedButtonStyleProps()}
        confirmText="Close"
        onConfirm={() => setShowDuplicateContractModal(false)}
        title="Duplicate contract(s) found"
      >
        <Box sx={styles.duplicateContractModal}>
          <Box sx={styles.duplicateContractModal.message}>{duplicateContractModalState?.message}</Box>
          {duplicateContractModalState?.contracts &&
            duplicateContractModalState.contracts.map((contract, idx) => {
              return (
                <Box sx={styles.duplicateContractModal.linkWrapper} key={idx}>
                  <Link to={`${RouteEnum.Contract}/${contract.contractId}`} target={`_blank`} id={`lnkOpenContractInNewWindow${contract.contractId}`}>
                    {contract.vendorContractNumber}
                  </Link>
                </Box>
              );
            })}
        </Box>
      </DbgDialog>
      <DbgDialog
        id="deleteConfirmationDialog"
        open={showDeleteConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={handleContractDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
        title="Are you sure you want to delete this contract?"
      />
      <DbgDialog
        id="deleteTermConfirmationDialog"
        open={uiContractPageState.canSaveOrProposeChange() && showDeleteTermConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={onContractTermModalDelete}
        onCancel={() => setShowDeleteTermConfirmation(false)}
        title={`Are you sure you want to delete this ${constants.terms.displayName}?`}
      />
      <DbgDialog
        id="unsavedChangesDialog"
        open={uiContractPageState.canSaveOrProposeChange() && showUnsavedChanges}
        cancelText="Cancel"
        sx={[dlgStyles.dialogContent, styles.unsavedChangesModal]}
        confirmButtonTheme={getGreenButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Save"
        onConfirm={handleContractSaveAndReload}
        onCancel={() => setShowUnsavedChanges(false)}
        title={contractErrors.length > 0 ? 'Validation Errors' : 'Unsaved Changes'}
        hideFooterActions={true}
      >
        {contractErrors.length > 0 && (
          <>
            <Box sx={styles.unsavedChangesModalContent}>Please resolve validation errors or continue back to all contracts and discard changes.</Box>
            <Box>
              <DbgRoundedButton
                dbgButtonSize={DbgButtonSize.Large}
                tabIndex={-1}
                onClick={() => setShowUnsavedChanges(false)}
                sx={styles.unsavedChangesModalButtons}
                styleProps={getGreenButtonStyleProps()}
                id={`unsavedChangesDialogConfirm`}
              >
                Review and Resolve Errors
              </DbgRoundedButton>
            </Box>
            <Box>
              <DbgRoundedButton
                dbgButtonSize={DbgButtonSize.Large}
                onClick={handleDiscardChanges}
                sx={styles.unsavedChangesModalButtons}
                styleProps={getRedButtonStyleProps()}
                id={`unsavedChangesDialogDiscard`}
              >
                Continue and Discard Changes
              </DbgRoundedButton>
            </Box>
          </>
        )}
        {contractErrors.length === 0 && (
          <>
            <Box sx={styles.unsavedChangesModalContent}>Would you like to save your changes before returning to all contracts?</Box>
            <Box>
              <DbgRoundedButton
                dbgButtonSize={DbgButtonSize.Large}
                tabIndex={-1}
                onClick={handleContractSaveAndClose}
                sx={styles.unsavedChangesModalButtons}
                styleProps={getGreenButtonStyleProps()}
                id={`unsavedChangesDialogConfirm`}
                disabled={saving}
              >
                {saving ? (
                  <>
                    <DbgLoadingSpinner id="unsavedChangesModelSaving" sx={[styles.saveContractBtn.savingIcon]} />
                    Saving...
                  </>
                ) : (
                  <>Yes, Save Changes</>
                )}
              </DbgRoundedButton>
              <Box>
                <DbgRoundedButton
                  dbgButtonSize={DbgButtonSize.Large}
                  onClick={handleDiscardChanges}
                  sx={styles.unsavedChangesModalButtons}
                  styleProps={getRedButtonStyleProps()}
                  id={`unsavedChangesDialogDiscard`}
                >
                  No, Continue Without Saving
                </DbgRoundedButton>
              </Box>
              <Box>
                <DbgRoundedButton
                  dbgButtonSize={DbgButtonSize.Large}
                  onClick={() => setShowUnsavedChanges(false)}
                  sx={styles.unsavedChangesModalButtons}
                  styleProps={getWhiteButtonStyleProps()}
                  id={`unsavedChangesDialogCancel`}
                >
                  Cancel
                </DbgRoundedButton>
              </Box>
            </Box>
          </>
        )}
      </DbgDialog>
      <DbgDialog
        id="proposedChangeMessage"
        open={uiContractPageState.showProposedChangesMessageOnce && !showProposedChangesMessageAlready}
        sx={[dlgStyles.dialogContent, styles.proposedChangesModal]}
        showCloseIcon={true}
        confirmText="Ok"
        onConfirm={() => setShowProposedChangesMessageAlready(true)}
        title="Proposed changes"
        confirmButtonTheme={getDarkGreyButtonStyleProps()}
      >
        <Box sx={styles.unsavedChangesModalContent}>
          You're currently viewing a contract with proposed changes. Choose to discard or keep in order to save the contract.
          <Button
            id={'ViewLog'}
            variant="link"
            text="View Changes"
            className={css({ marginTop: '30px' })}
            onClick={() => {
              setShowProposedChangesMessageAlready(true);
              setShowTermsHistoryDialog(true);
            }}
          />
        </Box>
      </DbgDialog>
      <DbgDialog
        id="beforeProposeChangesMessage"
        open={beforeProposeChangesMessage}
        cancelText="Cancel"
        sx={[dlgStyles.dialogContent, styles.beforeProposeChangesModal]}
        showCloseIcon={true}
        confirmText="Yes, save"
        onConfirm={handleProposeChanges}
        onCancel={() => setBeforeProposeChangesMessage(false)}
        title="Propose changes"
        confirmButtonTheme={getGreenButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
      >
        <Box sx={styles.unsavedChangesModalContent}>The contract will be read-only until the proposed contract is kept or discarded.</Box>
      </DbgDialog>
    </>
  );
}
