import React, { useCallback } from 'react';
import { ContractPanelAction, Permission, ProposalAction, UserType, WorkflowAction, WorkflowStatus } from '../../../../models/enums';
import { ContractPageStyles } from '../styles/ContractPageStyles';
import CheckIcon from '@mui/icons-material/Check';
import { IUIContractPageState } from '../UIContractPageState';
import TasksButton from './TasksButton';
import { ContractTasks } from './dialogs/ContractTasks';
import { Button } from '@dierbergs-markets/react-component-library';
import PermissionFilter from '../../../components/shared/PermissionFilter';
import { IContract } from '../../../../models';
import { useRefreshNotificationCounterState } from '../../../../store';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { tss } from 'tss-react';

interface IProps {
  currentWorkflowStatus: WorkflowStatus | null;
  onUpdate: (action: ContractPanelAction) => void;
  uiContractPageState: IUIContractPageState;
  contract: IContract;
}

export default function ContractActionPanel(props: IProps) {
  const { css } = useStyles();
  const { uiContractPageState, currentWorkflowStatus, onUpdate, contract } = props;
  const { user } = useApplicationContextState();
  const contractId = contract.contractId;
  const increaseNotificationRefershCounter = useRefreshNotificationCounterState((state) => state.increaseCounter);

  const refreshNotification = useCallback(() => {
    if (user?.userType === UserType.Internal) {
      increaseNotificationRefershCounter();
    }
  }, [increaseNotificationRefershCounter]);
  return (
    <>
      <div style={ContractPageStyles.styles.footerStatus}>{currentWorkflowStatus}</div>
      {uiContractPageState.showAcceptContractButton && (
        <Button
          id="AcceptWorkflow"
          variant={'rounded-sides'}
          startIcon={<CheckIcon />}
          color="green"
          text="Accept"
          onClick={() => onUpdate(WorkflowAction.ACCEPT)}
          classes={{
            root: css({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
          }}
        ></Button>
      )}
      {uiContractPageState.showTasksButton && contractId && (
        <PermissionFilter permission={Permission.TasksCreate}>
          <div style={{ paddingLeft: '5px' }}>
            <TasksButton contractId={contractId}>
              <ContractTasks />
            </TasksButton>
          </div>
        </PermissionFilter>
      )}
      {uiContractPageState.showSubmitContractButton && (
        <div style={{ paddingLeft: '5px' }}>
          <Button
            id="SubmitWorkflow"
            variant={'rounded-sides'}
            color="green"
            text=" Submit Contract"
            onClick={() => onUpdate(WorkflowAction.SUBMIT)}
          ></Button>
        </div>
      )}
      {uiContractPageState.showDeclineOrKeepProposalButton && (
        <>
          <div style={{ paddingLeft: '5px' }}>
            <Button
              id="SubmitWorkflow"
              variant={'rounded-sides'}
              color="red"
              text="No, discard proposal"
              onClick={() => {
                onUpdate(ProposalAction.DROP_PROPOSAL);
                refreshNotification();
              }}
            ></Button>
          </div>
          <div style={{ paddingLeft: '5px' }}>
            <Button
              id="SubmitWorkflow"
              variant={'rounded-sides'}
              color="green"
              text="Yes, keep proposal"
              onClick={() => {
                onUpdate(ProposalAction.KEEP_PROPOSAL);
                refreshNotification();
              }}
            ></Button>
          </div>
        </>
      )}
    </>
  );
}

const useStyles = tss.create({
  root: {},
});
