import { IUpcSearchForContractResponse, IUpcSearchResponse } from '../models/responses';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const itemService = {
  searchByUpc,
  searchByOrderCode,
  searchByUpcForContract,
  searchByOrderCodeForContract,
};

function searchByUpc(searchValue: number): Promise<IUpcSearchResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchResponse>({ endpoint: 'item', query: { upc: searchValue } });
}

function searchByOrderCode(searchValue: string): Promise<IUpcSearchResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchResponse>({ endpoint: 'item', query: { code: searchValue } });
}

function searchByUpcForContract(
  searchValue: number,
  supplierId: number,
  stores: number[]
): Promise<IUpcSearchForContractResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchForContractResponse>({ endpoint: 'item/contract', query: { upc: searchValue, supplierId, stores } });
}

function searchByOrderCodeForContract(
  searchValue: string,
  supplierId: number,
  stores: number[]
): Promise<IUpcSearchForContractResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchForContractResponse>({ endpoint: 'item/contract', query: { code: searchValue, supplierId, stores } });
}
