import { SystemAlert } from '../../models';
import { fetchGetJson, HttpErrorResponse } from '../contractHubApi';

export const systemAlertService = {
  getSystemAlert,
};

async function getSystemAlert(): Promise<SystemAlert | null | HttpErrorResponse> {
  const response = await fetchGetJson<SystemAlert>({ endpoint: `system-alerts` });
  if (response instanceof HttpErrorResponse && response.status === 404) return null;
  return response;
}
