import { tss } from 'tss-react';
import { IContractTermsHistory } from '../../../../../models';
import { CoreDialog, CoreDialogContent, CoreDialogTitle, Spinner } from '@dierbergs-markets/react-component-library';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { contractTermService } from '../../../../../services';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { defaultColors } from '../../../../../styles/variables';
import { format } from 'date-fns';
import { ContractTermHistoryEventTypes, ContractTermHistoryResolutionTypes, WorkflowStatus } from '../../../../../models/enums';
import CircleLogo from '../../../../../images/d_circle_logo.png';
import { MdPerson } from 'react-icons/md';
import { StyledImg } from '../../../../../styles/styled/StyledWrappedCommonElements';

interface IProps {
  contractId: number;
  onClose: () => void;
}

export default function ContractTermsHistory(props: IProps) {
  //Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { css, cx, classes } = useStyles();
  //State
  const [history, setHistory] = useState<IContractTermsHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //Effects
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await contractTermService.getHistory(props.contractId);
      if (response instanceof HttpErrorResponse) {
        const errorText = 'Unable to load history.';
        console.log(errorText);
        enqueueSnackbar(errorText, { variant: 'error' });
        return;
      }
      setLoading(false);

      setHistory(response);
    })();
  }, [props.contractId]);

  //VARIABLES
  const ResolutionTypeToBgColor = {
    [ContractTermHistoryResolutionTypes.Approved]: defaultColors.green,
    [ContractTermHistoryResolutionTypes.Declined]: defaultColors.red,
  };

  const ResolutionTypeToActionVerb = {
    [ContractTermHistoryResolutionTypes.Approved]: 'Approved',
    [ContractTermHistoryResolutionTypes.Declined]: 'Declined',
  };

  //FUNCTIONS
  function renderTag(historyItem: IContractTermsHistory) {
    let bgColor = defaultColors.blue;
    let text = '';
    switch (historyItem.eventType) {
      case ContractTermHistoryEventTypes.Version:
      case ContractTermHistoryEventTypes.Proposal:
        text =
          historyItem.versionNumber != null
            ? `version ${historyItem.versionNumber}`
            : historyItem.resolution
              ? ResolutionTypeToActionVerb[historyItem.resolution]
              : 'Proposed Version';
        if (historyItem.resolution) bgColor = ResolutionTypeToBgColor[historyItem.resolution];
        if (!historyItem.resolution && !historyItem.versionNumber) bgColor = defaultColors.yellow;
        break;
      case ContractTermHistoryEventTypes.Status:
        bgColor = defaultColors.darkGrey;
        text = historyItem.description ?? '';
        break;
      default:
        return <></>;
    }

    return (
      <div className={cx(classes.timelineTag)} style={{ backgroundColor: bgColor }} data-name="tag">
        {text.toLowerCase()}
      </div>
    );
  }

  function workflowStatusToActionVerb(worklowStatus: string) {
    return worklowStatus === WorkflowStatus.IN_REVIEW ? 'Reviewed' : worklowStatus;
  }

  function displayUserWorkflowAction(historyItem: IContractTermsHistory) {
    let actionVerb = '';
    switch (historyItem.eventType) {
      case ContractTermHistoryEventTypes.Proposal:
        actionVerb = 'Proposed';
        break;
      case ContractTermHistoryEventTypes.Status:
        actionVerb = workflowStatusToActionVerb(historyItem.description ?? '').toLowerCase();
        break;
      case ContractTermHistoryEventTypes.Version:
      default:
        actionVerb = 'Created';
        break;
    }
    return (
      <div className={classes.createdByUser}>
        {historyItem.createdBy && displayUserIcon(historyItem.createdBy?.isInternal)}{' '}
        <span style={{ textTransform: 'capitalize', marginRight: '4px' }}>{actionVerb}</span> by {historyItem.createdBy?.displayName}{' '}
        {historyItem.createdBy && !historyItem.createdBy.isActive ? '(Inactive)' : ''}
      </div>
    );
  }

  function displayUserIcon(isInternal: boolean) {
    return isInternal ? <StyledImg src={CircleLogo} className={classes.logo} /> : <MdPerson className={classes.logo} />;
  }

  function displayUserResolutionAction(historyItem: IContractTermsHistory) {
    if (!historyItem.resolvedBy) return <></>;
    let actionVerb = '';
    actionVerb = historyItem.resolution ? ResolutionTypeToActionVerb[historyItem.resolution] : '';
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
        })}
      >
        <span className={css({ display: 'inline-flex', alignItems: 'center' })}>
          {displayUserIcon(historyItem.resolvedBy?.isInternal)}
          {actionVerb} by {historyItem.resolvedBy?.displayName}
        </span>
        <span className={css({ marginLeft: '20px' })}>on {format(historyItem.resolvedAtUtc, 'MM/dd/yy @ h:mm:ss a')}</span>
      </div>
    );
  }

  return (
    <CoreDialog
      onClose={props.onClose}
      open={true}
      classes={{
        root: css(classes.root),
      }}
    >
      <CoreDialogTitle>Cumulative Contract Changes</CoreDialogTitle>
      <CoreDialogContent className={classes.dialogContent}>
        {loading && (
          <div>
            loading <Spinner id={''} />
          </div>
        )}
        {(!loading && !history) || history.length === 0 ? (
          <>No history found.</>
        ) : (
          history.map((h, i) => (
            <div key={i} className={classes.event} data-name="event">
              <div className={classes.whoWhenActions} data-name="whoWhenActions">
                <div className={classes.whoWhen}>
                  <div className={classes.eventTimeStamp}>
                    <span className={classes.eventDate}>{format(h.createdAtUtc, 'MMMM d, yyyy')} </span>
                    <span className={classes.eventTime}>@ {format(h.createdAtUtc, 'h:mm:ss a')}</span>
                  </div>
                  {displayUserWorkflowAction(h)}
                  <div className={classes.resolutionContainer}>
                    {h.resolvedAtUtc && <div className={classes.user}>{displayUserResolutionAction(h)}</div>}
                  </div>
                </div>
              </div>
              <div className={classes.timeline}>
                <div className={classes.timelineBar} data-name="timelineBar"></div>
                {renderTag(h)}
              </div>
              <div className={classes.eventLog} data-name="log">
                {!h.deltaLog && h.versionNumber === 1 && (
                  <ul className={classes.log}>
                    <li className={classes.logItem}>Initial contract save</li>
                  </ul>
                )}
                {h.deltaLog && (
                  <ul className={classes.log}>
                    {h.deltaLog
                      .filter((l) => l.length > 0)
                      .map((logItem, li) => {
                        return (
                          <li key={li} className={classes.logItem}>
                            {logItem}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </div>
          ))
        )}
      </CoreDialogContent>
    </CoreDialog>
  );
}

const useStyles = tss.create(() => {
  const tagWidth = 126;
  const minEventHeight = '85px';
  const eventTopMargin = '15px';

  return {
    root: {
      width: '65%',
      minWidth: '800px',
      height: '75%',
    },
    dialogContent: {
      '& :first-of-type [data-name="tag"], & :first-of-type [data-name="whoWhenActions"], & :first-of-type [data-name="log"]': {
        marginTop: '0px',
      },
      '& :last-of-type [data-name="timelineBar"]': {
        height: '15px', // set explicit height to the bar does not appear below the tag on the last event.
      },
    },
    event: {
      width: '100%',
      display: 'flex',
      alignItems: 'stretch', // Make sure children expand fully
      minHeight: minEventHeight,
      cursor: 'default',
      '&:not(:last-of-type)': {
        borderBottom: `2px dotted ${defaultColors.border}`,
      },
    },
    whoWhenActions: {
      flex: 1,
      minWidth: '300px',
      marginTop: eventTopMargin,
    },
    whoWhen: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      fontSize: '14px',
    },
    timeline: {
      width: `${tagWidth}px`,
      minWidth: `${tagWidth}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: minEventHeight, // Ensure it has enough height
      position: 'relative', // Allows positioning if needed
    },
    timelineBar: {
      width: '2px',
      height: '100%',
      backgroundColor: defaultColors.border,
      alignSelf: 'center', // Center it within the timeline container
    },
    timelineTag: {
      width: '100%',
      height: '26px',
      fontSize: '12px',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: defaultColors.white,
      textTransform: 'capitalize',
      position: 'absolute', // Positioning at the top of the timeline
      top: 0, // Anchors it to the top
      marginTop: eventTopMargin,
    },
    eventLog: {
      flex: 1,
      minWidth: '300px',
      height: '100%',
      marginTop: eventTopMargin,
    },
    eventTimeStamp: {
      display: 'flex',
      alignItems: 'center',
    },
    eventDate: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: defaultColors.darkGrey,
    },
    eventTime: {
      fontSize: '12px',
      marginLeft: '5px',
    },
    createdByUser: {
      fontSize: '12px',
      display: 'inline-flex',
    },
    resolutionContainer: {
      marginTop: '15px',
      color: defaultColors.mediumGrey,
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    log: {
      marginTop: '2px',
      marginLeft: '45px',
      listStyleType: 'circle',
    },
    logItem: {
      marginBottom: '5px',
    },
    logo: {
      width: '18px',
      height: '18px',
      marginRight: '3px',
    },
  };
});
